<template>
  <a-card :bordered="false">
    <a-card class="card" :title="'报告编号：' + reportInfo.reportNo" :bordered="false">
      <div slot="extra">
        <a-button @click="backReport" style="margin-right:15px;"><a-icon type="caret-left" /> 返回</a-button>
        <a-button @click="exportReport" style="margin-right:15px;"><a-icon type="download" /> 导出</a-button>
        <a-button type="primary" @click="reportEdit" v-if="permission_edit"><a-icon type="edit"/>编辑</a-button>
      </div>
      <a-descriptions title="工程信息">
        <a-descriptions-item label="供水工程">{{ reportInfo.projectName }}</a-descriptions-item>
        <a-descriptions-item label="工程位置">{{ reportInfo.cwsLoc }}</a-descriptions-item>
        <a-descriptions-item label="检测单位">{{ reportInfo.realCenterName }}</a-descriptions-item>
        <a-descriptions-item label="工程类型">{{ reportInfo.projectTypeName }}</a-descriptions-item>
        <a-descriptions-item label="水源类型">{{ reportInfo.waterTypeName }}</a-descriptions-item>
        <a-descriptions-item label="供水季节">{{ reportInfo.season }}</a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px"/>
      <a-descriptions title="检测信息">
        <a-descriptions-item label="采样日期">{{ reportInfo.sampleDate }}</a-descriptions-item>
        <a-descriptions-item label="检测日期">{{ reportInfo.detectionDate }}</a-descriptions-item>
        <a-descriptions-item label="编制日期">{{ reportInfo.reportDate }}</a-descriptions-item>
        <a-descriptions-item label="采样人员">{{ reportInfo.sampleName }}</a-descriptions-item>
        <a-descriptions-item label="编制人员">{{ reportInfo.detectionName }}</a-descriptions-item>
        <a-descriptions-item label="审核人员">{{ reportInfo.verifyName }}</a-descriptions-item>
        <a-descriptions-item label="批准人员">{{ reportInfo.acceptName }}</a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px"/>
      <a-descriptions title="检测结果">
        <a-descriptions-item label="检测结果">{{ reportInfo.result }}</a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card class="card" title="检测报告水样信息" :bordered="false">
      <div v-for="(item, i) in reportInfo.reportSampleEntities" :key="item.id" style="margin:0 0 20px 0;">
        <h4><font style="font-weight:bold">{{ i+1 }}】 水样编号：{{ item.waterCode }} / 水样类型：{{ item.sampleTypeName }} / 采样地点：{{ item.addr }}</font></h4>
        <a-table
                :columns="column"
                :dataSource="item.reportDataEntities"
                :pagination="false"
                :loading="memberLoading"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <span slot="qualified" slot-scope="text">
            <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
          </span>
        </a-table>
      </div>
    </a-card>
  </a-card>
</template>

<script>
import { getReportInfo ,getReportWord} from '@/api/quality/project'
import { getMenuList } from '@/api/basic/common'

const statusMap = {
  0: {
    status: 'error',
    text: '不合格'
  },
  1: {
    status: 'success',
    text: '合格'
  }
}

export default {
  name: 'AdvancedForm',
  components: {
  },
  data () {
    return {
      loading: false,
      memberLoading: false,

      // table
      column: [
        {
          title: '#',
          dataIndex: 'serial',
          key: 'serial',
          width: '8%',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '指标',
          dataIndex: 'detectName',
          key: 'detectName',
          width: '15%',
          scopedSlots: { customRender: 'detectName' }
        },
        {
          title: '指标单位',
          dataIndex: 'unit',
          key: 'unit',
          width: '15%',
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '检测值',
          dataIndex: 'dataValue',
          key: 'dataValue',
          width: '15%',
          scopedSlots: { customRender: 'dataValue' }
        },
        {
          title: '指标限值',
          dataIndex: 'limitValue',
          key: 'limitValue',
          width: '15%',
          scopedSlots: { customRender: 'limitValue' }
        },
        {
          title: '是否合格',
          dataIndex: 'qualified',
          key: 'qualified',
          width: '10%',
          scopedSlots: { customRender: 'qualified' }
        },
        {
          title: '检测方法',
          dataIndex: 'methodName',
          key: 'methodName',
          width: '20%',
          scopedSlots: { customRender: 'methodName' }
        }
      ],
      errors: [],
      // 接口返回
      reportInfo: {},
      waterTypeMenuList: []
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {
    this.permission_edit = localStorage.getItem("permission_edit") == 'true'

    console.log("permission_edit = "+this.permission_edit)

    this.getReport()
    this.getWaterTypeMenu()
  },
  methods: {
    // 接口请求
    getReport () {
      getReportInfo({ id: this.$route.query.reportId }).then(response => {
        this.reportInfo = response.data
      })
    },
    // 获取菜单
    getWaterTypeMenu () {
      getMenuList({ menu: '水样类型' }).then(response => {
        this.waterTypeMenuList = response.data.list
        // this.listParam.waterType = response.data.list[0].value
      })
    },
    reportEdit () {
      this.$router.push({
        path: '/test/report/edit',
        query: {
          reportId: this.$route.query.reportId
        }
      })
    },
    exportReport() {
      getReportWord({ id: this.$route.query.reportId }).then(response => {
        this.reportPath = response.data
        if (response.code === 0) {
          //调用下载接口
          window.location = response.data;
        } else {
          this.$message.error('检测报告导出错误：'+response.msg)
        }
      })
    },
    backReport () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 24px;
  }
</style>
